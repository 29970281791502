.error-page {
    min-height: 100vh;
    background: linear-gradient(45deg, #0d6efd 0%, #0dcaf0 100%);
}

.error-container {
    max-width: 600px;
}

.error-code {
    font-size: 3rem;
    font-weight: 900;
    background: linear-gradient(to right, #f10404, rgba(255, 255, 255, 0.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: pulse 2s infinite;
}

.bravo{
    font-size: 3rem;
    font-weight: 900;
    background: linear-gradient(to right, #13d601, rgba(255, 255, 255, 0.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: pulse 2s infinite;
}

.wait-code{
    font-size: 3rem;
    font-weight: 900;
    background: linear-gradient(to right, #eff700, rgba(255, 255, 255, 0.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: pulse 2s infinite;
}

.error-message {
    color: rgba(255, 255, 255, 0.9);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.btn-glass {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    transition: all 0.3s ease;
}

.btn-glass:hover {
    background: rgba(255, 255, 255, 0.3);
    color: white;
}